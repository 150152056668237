
import CompTable from "@/components/comp-table.vue"

import Request from "@/module/request/request"
import Utils from "@/module/utils/utils"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            title: "",
            columns: null,
            tableApi: null
        }
    },

    watch: {
        $route(to: obj) {
            if (to.path === "/approval/vehicle_change") {
                this.initial()
            }
        },
    },

    mounted() {
        this.initial()
    },

    methods: {
        initial() {
            const type = Utils.getParam("t")

            var items = []

            switch (type) {
                case "VEHICLE_CHANGE":
                    this.title = "车辆套餐变更审批"

                    items.push({
                        title: "原套餐",
                        key: "oldSetMeal",
                    }, {
                        title: "新套餐",
                        key: "newSetMeal",
                    })

                    break
                case "VEHICLE_TRANSFER":
                    this.title = "车辆转出审批"

                    items.push({
                        title: "购买人名称",
                        key: "buyerName",
                    }, {
                        title: "转出手续费",
                        render: (h: Function, params: obj) => {
                            return "￥" + (!params.row.transferAmount ? "0.00" : (params.row.transferAmount / 100).toFixed(2))
                        },
                    })

                    break
                case "VEHICLE_ARCHIVE":
                    this.title = "车辆归档审批"
                    break
            }

            this.columns = [
                {
                    title: "审批状态",
                    render: (h: Function, params: obj) => {
                        return h(
                            "span",
                            {
                                style: {
                                    color: ["#00e333", "#67C23A", "#F56C6C", "#909399"][parseInt(params.row.approvalStatus)],
                                },
                            },
                            ["待审核", "已通过", "已拒绝", "取消申请"][parseInt(params.row.approvalStatus)] || "未知"
                        )
                    },
                },
                {
                    title: "车辆",
                    key: "vehicle",
                },
                ...items,
                {
                    title: "备注",
                    key: "note",
                },
                {
                    title: "申请日期",
                    key: "createdDate",
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 200,
                    slot: "row-operate",
                },
            ]

            this.tableApi = "WEB://VehicleChange/FindAllToPage?type=" + type
        },

        /**
         * 同意/拒绝申请
         */
        onChangeApply(uuid: string, status: string) {
            Request.post(
                "WEB://VehicleChange/Approve",
                {
                    vehicleChange: uuid,
                    approvalStatus: status,
                },
                {
                    success: "操作成功",
                }
            ).then(() => {
                // 刷新表格
                this.$refs.comp_table.refresh()
            })
        },
    },
}
